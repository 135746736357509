import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        description
        promo
        pic
      }
      fields {
        name
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Grid is Perfect for Responsive Layout",
  "date": "2017-09-18",
  "promo": "grids",
  "description": "CSS Grid will save you a lot of time and trouble over Flexbox for responsive layouts.",
  "pic": "/img/grid-beats-flexbox.jpg",
  "color": "#bcff00"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`We've already seen that `}<a parentName="p" {...{
        "href": "/post/grid-beats-flexbox-full-page-layout/"
      }}>{`Grid beats Flexbox for Full Page Layout`}</a>{`. But what about responsive designs? Are you better off using Flexbox or Grid? Both tools appear equipped to handle varying screen sizes. You might think Flexbox is a better fit, after all - Flex items can `}<inlineCode parentName="p">{`grow`}</inlineCode>{`, `}<inlineCode parentName="p">{`shrink`}</inlineCode>{`, change `}<inlineCode parentName="p">{`direction`}</inlineCode>{`, `}<inlineCode parentName="p">{`wrap`}</inlineCode>{`, etc. But Grid layout actually comes with a few new tricks that will save you a lot of time and trouble for responsive layouts.`}</p>
    <h2>{`Minmax for the Win`}</h2>
    <p>{`The first shiny new tool Grid comes with is `}<strong parentName="p">{`minmax`}</strong>{`, which lets us define `}<strong parentName="p">{`ranges`}</strong>{` for the columns and rows in our layout. In this example we've got an orange sidebar that we want to be `}<em parentName="p">{`at least`}</em>{` `}<inlineCode parentName="p">{`100px`}</inlineCode>{` wide, but no more than 30% of the width of the container. Here the container is `}<inlineCode parentName="p">{`500px`}</inlineCode>{` wide, so our sidebar happily takes up 30% of it (`}<inlineCode parentName="p">{`150px`}</inlineCode>{`).`}</p>
    {
      /* I'm hopeful that in the future `minmax` will expand to work on other CSS properties like `grid-gap`, `border`, etc. */
    }
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      grid-template-columns: minmax(100px, 30%) 1fr;
      grid-template-rows: 100px 1fr 100px;
    }\n
    .grey, .purple {
      grid-column: span 2;
    }
  `} fixedCSS={`
  .container {
    height: 350px;
  }
  .item {
    box-sizing: border-box;
  }  
  `} html={`
  <div class="container">
    <div class="item grey"></div>  
    <div class="item orange"></div>
    <div class="item blue"></div>
    <div class="item purple"></div>
  </div>
  `} mdxType="CodeExample" />
    <p>{`Here's the same exact code but in a smaller container. Now our minimum value kicks in and makes sure the orange sidebar doesn't go any smaller than `}<inlineCode parentName="p">{`100px`}</inlineCode>{`.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      width: 150px;
      grid-template-columns: minmax(100px, 30%) 1fr;
      grid-template-rows: 100px 1fr 100px;
    }\n
    .grey, .purple {
      grid-column: span 2;
    }
  `} fixedCSS={`
  .container {
    height: 350px;
  }
  `} html={`
  <div class="container">
    <div class="item grey"></div>  
    <div class="item orange"></div>
    <div class="item blue"></div>
    <div class="item purple"></div>
  </div>
  `} mdxType="CodeExample" />
    <p>{`Minmax ranges like this are awesome and in many cases mean you won't even need to use media queries.`}</p>
    <p>{`We can accomplish a similar effect using Flexbox, but it requires a bit more work. We have to use a combination of `}<inlineCode parentName="p">{`flex-grow`}</inlineCode>{`, `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`, `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` to pull it off:`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: flex;
      width: 500px;
      height: 400px;
      flex-wrap: wrap;
    }\n
    .orange {
      flex-grow: 1;
      min-width: 100px;
      max-width: 30%;
    }\n
    .blue {
      flex-grow: 1;
    }\n
    .grey, .purple {
      flex-grow: 1;
      width: 100%;
    }
  `} fixedCSS={`
  .container {
  }
  `} html={`
    <div class="container">
      <div class="item grey"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
      <div class="item purple"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`And there's a key difference here: with Grid we're sizing the `}<strong parentName="p">{`track`}</strong>{` where the orange item will go. With Flexbox we have to size the orange item itself. The Grid method is more flexible and easier to adapt.`}</p>
    <h2>{`One Style to Rule Them All`}</h2>
    <p>{`If you `}<em parentName="p">{`do`}</em>{` end up needing media queries, with Grid layout you'll generally have less you need to change to make things adapt. Say we want to change this layout to a single column on mobile. With Flexbox we'd need to change each affected item in the layout, overriding its `}<inlineCode parentName="p">{`min-width`}</inlineCode>{`. We don't have to `}<inlineCode parentName="p">{`unset`}</inlineCode>{` the `}<inlineCode parentName="p">{`max-width`}</inlineCode>{` since `}<inlineCode parentName="p">{`min-width`}</inlineCode>{` takes precedence.`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: flex;
      width: 500px;
      height: 400px;
      flex-wrap: wrap;
    }\n
    .orange {
      flex-grow: 1;
      min-width: 100px;
      max-width: 30%;
    }\n
    .blue {
      flex-grow: 1;
    }\n
    @media (max-width: 1000px) { 
      .orange{
        min-width: 100%;
      }
      .blue{
        min-width: 100%;
      }
    }\n
    .grey, .purple {
      flex-grow: 1;
      width: 100%;
    }
  `} fixedCSS={`
  .container {
  }
  `} html={`
    <div class="container">
      <div class="item grey"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
      <div class="item purple"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`For just a couple of items this isn't too bad, but the more complex your layout the more items you'll have to override, for every media query. It's doable, but the Grid way is much cleaner.`}</p>
    <p>{`All we have to do is tell the items to span two columns, and we're done!`}</p>
    <CodeExample theme="grid" live={true} showCSS={`
    .container {
      display: grid;
      width: 500px;
      height: 400px;
      grid-template-columns: minmax(100px, 30%) 1fr;
      grid-template-rows: 100px 1fr 100px;
      grid-auto-rows: 1fr;
    }\n
    @media (max-width: 1000px) { 
      .item {
        grid-column: span 2;
      }
    }\n
    .grey, .purple {
      grid-column: span 2;
    }
  `} html={`
    <div class="container">
      <div class="item grey"></div>  
      <div class="item orange"></div>
      <div class="item blue"></div>
      <div class="item purple"></div>
    </div>
  `} mdxType="CodeExample" />
    <p>{`You can even change your `}<em parentName="p">{`entire grid`}</em>{` with a single rule if you want, altering the size & number of columns, rows, gaps, etc. CSS Grid is a fantastic choice for responsive layouts.`}</p>
    <h2>{`Challenge`}</h2>
    <p>{`Build a responsive design using Grid layout. Play around with `}<inlineCode parentName="p">{`minmax`}</inlineCode>{` ranges, and experiment with how much you can alter the Grid for different media query breakpoints.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      